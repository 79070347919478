import * as React from "react"

import LayoutWhite from "../components/layoutWhite"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <LayoutWhite
	seccion="mediosDePago"	
	title="Página no encontrada"	
	linkTo="experienciaContainer"
  >
    <Seo title="404: Not found" />
    <div id="whiteContainer" className="row">
				<div className="col-12"
						data-aos='fade-up'
						data-aos-duration="600" data-aos-once="true">
					<h2 className="text-center mt-5">Página no encontrada</h2>
				</div>			
			</div>
  </LayoutWhite>
)

export default NotFoundPage
